class ResponseUserSettingsModel {
    constructor({
                    clearingNumber = '',
                    accountNumber = '',
                    warning = '',
                } = {}) {
        this.clearingNumber = clearingNumber;
        this.accountNumber = accountNumber;
        this.warning = warning;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const attributes = {
            clearingNumber :{
                'label': 'Clearing Number',
                'sortable': true,
                'sortField': "clearingNumber",
                'value': (item) => item.clearingNumber || "",
            },
            accountNumber :{
                'label': 'Account Number',
                'sortable': true,
                'sortField': "accountNumber",
                'value': (item) => item.accountNumber || "",
            },
        }

        return attributes;
    }
}

export default ResponseUserSettingsModel;