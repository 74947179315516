import {useTranslation} from "react-i18next";
import {Card, Col, Row} from "reactstrap";
import {API_URL} from "../../config";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import React from "react";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function CustomReimbursementExpenseView({data, accountingResponse = null, editMode, onClickDelete, onClickEdit}) {
    const { t } = useTranslation();

    return(
        <>
            {data && data.length > 0 ? (
                    data.map((item, index) => {
                        let additionalKey = "";
                        let additionalValue = "";
                        if (item.additionalInfo && Object.keys(item.additionalInfo).length > 0) {
                            for (const key of Object.keys(item.additionalInfo)) {
                                additionalKey = key;
                                additionalValue = item.additionalInfo[key];
                            }
                        }
                        let accountingName = "";
                        if (accountingResponse !== null) {
                            accountingResponse.forEach((accounting) => {
                                if (accounting.id === item.accountingId) {
                                    accountingName = accounting.showLabel;
                                }
                            });
                        }
                        let additionalFile = "";
                        if (item.additionalInfo && Object.keys(item.additionalInfo).length > 0) {
                            if (item.additionalInfo.participantsFileId !== undefined && item.additionalInfo.participantsFileId !== "") {
                                additionalFile = item.additionalInfo.participantsFileId;
                            }
                            if (item.additionalInfo.programFileId !== undefined && item.additionalInfo.programFileId !== "") {
                                additionalFile = item.additionalInfo.programFileId;
                            }
                        }
                        return (
                            <Col lg={12} key={`expense${index}`} className="mt-1">
                                <Card className="bg-card-grey p-3">
                                    <Row>
                                        <Col lg={12}>
                                            <Row className="d-flex align-items-center">
                                                <Col xxl={data[index].status !== 'Förslag' ||  editMode === true ? 12 : 9} className={`order-1 order-xxl-0 ${data[index]?.status !== 'Förslag' || editMode === true ? 'pe-3 ' : 'pe-0 '}`}>
                                                    <Row className=" d-flex align-items-center">
                                                        <Col lg={3}>
                                                            {ChangeDateFormat('date', item.dateReceive)}
                                                        </Col>
                                                        <Col lg={5}> {/*className={item.vatAmount !== null ? 'd-flex justify-content-between' : ''}*/}
                                                            {data[index].id && data[index].id !== '' ?
                                                                data[index].accountingName
                                                                :
                                                                accountingName !== null ? accountingName : ''
                                                            }
                                                            {/*<span> {accountingName}</span>*/}
                                                            {/*<span> {item.vatAmount !== null ? item.vatAmount + 'kr' : ''} </span>*/}{/*Mirar si quieren el vat o no*/}
                                                        </Col>
                                                        <Col lg={1}>
                                                            <Row>
                                                                {item.fileExtension !== null && item.fileExtension !== ''  ?
                                                                    <Col lg={12} className="d-flex justify-content-center">
                                                                        <a href={`${API_URL}/file?id=${item.fileId}`} target="_blank" className="text-decoration-none text-green d-flex">
                                                                            <span className='align-self-center ps-2'>{t('reimbursement.receipt')}</span>
                                                                            <i className="bx bx-file fs-3"/>

                                                                        </a>
                                                                    </Col>
                                                                    : ''}
                                                                {item.additionalFileExtension && item.additionalFileExtension !== "" && item.additionalInfo && Object.keys(item.additionalInfo).length > 0 && additionalFile !== "" ?
                                                                    <Col lg={12} className="d-flex justify-content-center">
                                                                        <a href={`${API_URL}/file?id=${additionalFile}`} target="_blank" className="text-decoration-none text-green d-flex">
                                                                            <span className='align-self-center ps-2'>{t('reimbursement.receipt')}</span>
                                                                            <i className="bx bx-file fs-3"/>

                                                                        </a>
                                                                    </Col>
                                                                    : ''}
                                                                {/*{item.additionalFileExtension === "" && item.additionalInfo && Object.keys(item.additionalInfo).length > 0 && additionalValue !== "" ?
                                                                    <Col lg={12}  className="text-center">
                                                                        {item.additionalInfo && item.additionalInfo.program ? item.additionalInfo.program : additionalValue}
                                                                    </Col>
                                                                    :
                                                                    <Col lg={12}  className="text-center px-0">
                                                                        {item.additionalInfo && item.additionalInfo.program ?
                                                                            item.additionalInfo.program
                                                                            :''}
                                                                    </Col>}*/}
                                                                    </Row>

                                                        </Col>
                                                        <Col lg={3}  className= 'text-start text-lg-end ps-0'>
                                                            <span> {item.amount ? ToCustomDecimal(item.amount) : 0} kr</span>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row>
                                                        <Col lg={6}>
                                                            {item.fileExtension !== null && item.fileExtension !== '' ?
                                                                <Row className="pb-2">
                                                                    <Col lg={12} className="">
                                                                         VIEW CUSTOM ICON AND FILE NAME
                                                                        <a href={`${API_URL}/file?id=${item.fileId}`} target="_blank" className="text-decoration-none text-green d-flex">
                                                                            {item.fileExtension.includes("image") || item.fileExtension.includes('.png') || item.fileExtension.includes('.jpg') || item.fileExtension.includes('.jpeg') || item.fileExtension.includes('.gif') ? <i className="bx bx-image-alt fs-2"/> : ""}

                                                                            {item.fileExtension.includes("pdf") || item.fileExtension.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2"/> : ""}

                                                                            {item.fileExtension.includes("wordprocessingml") || item.fileExtension.includes("msword") ? <i className="bx bxs-file-doc fs-2"/> : ""}

                                                                            {item.fileExtension.includes("text/plain") || item.fileExtension.includes('.doc') || item.fileExtension.includes(".docx") || item.fileExtension.includes(".txt") ? <i className="bx bxs-file-txt fs-2"/> : ""}

                                                                            {item.fileExtension.includes("sheet") || item.fileExtension.includes("excel") || item.fileExtension.includes(".xlsx") || item.fileExtension.includes(".xls") ? <i className="bx bxs-file fs-2"/> : ""}

                                                                            <span className='align-self-center ps-2'>{item.fileName && item.fileName !== '' ? (item.fileName.length > 20 ? item.fileName.substring(0, 20) + "..." : item.fileName) : (item.fileExtension.length > 20 ? item.fileExtension.substring(0, 20) + "..." : item.fileExtension)}</span>
                                                                        </a>*/}
                                                                        {/* IMAGE PREVIEW
                                                                    <img alt="" src={`${API_URL}/file?id=${item.fileId}`} className={'img-fluid w-100 align-items-center bg-white'}/>
                                                                    </Col>
                                                                </Row>
                                                                : ""}
                                                        </Col>
                                                        <Col lg={6}>
                                                            {item.additionalFileExtension && item.additionalFileExtension !== "" && item.additionalInfo !== null && Object.keys(item.additionalInfo).length > 0 && additionalValue !== "" ?
                                                                <Row className="pb-2">
                                                                    <Col lg={12} className="d-flex justify-content-start justify-content-lg-end">
                                                                        <a href={`${API_URL}/file?id=${additionalValue}`} target="_blank" className="text-decoration-none text-green d-flex">
                                                                            {item.additionalFileExtension.includes("image") || item.additionalFileExtension.includes('.png') || item.additionalFileExtension.includes('.jpg') || item.additionalFileExtension.includes('.gif') || item.additionalFileExtension.includes('.jpeg') ? <i className="bx bx-image-alt fs-2"/> : ""}

                                                                            {item.additionalFileExtension.includes("pdf") || item.additionalFileExtension.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2"/> : ""}

                                                                            {item.additionalFileExtension.includes("wordprocessingml") || item.additionalFileExtension.includes("msword") || item.additionalFileExtension.includes('.doc') || item.additionalFileExtension.includes(".docx") || item.additionalFileExtension.includes('.doc') || item.additionalFileExtension.includes(".docx") ? <i className="bx bxs-file-doc fs-2"/> : ""}

                                                                            {item.additionalFileExtension.includes("text/plain") ||item.additionalFileExtension.includes(".txt") ? <i className="bx bxs-file-txt fs-2"/> : ""}

                                                                            {item.additionalFileExtension.includes("sheet") || item.additionalFileExtension.includes("excel") || item.additionalFileExtension.includes(".xlsx") || item.additionalFileExtension.includes(".xls")? <i className="bx bxs-file fs-2"/> : ""}

                                                                            <span className='align-self-center ps-2'>{item.additionalFileName && item.additionalFileName !== '' ? (item.additionalFileName.length > 20 ? item.additionalFileName.substring(0, 20) + "..." : item.additionalFileName) : (item.additionalFileExtension.length > 20 ? item.additionalFileExtension.substring(0, 20) + "..." : item.additionalFileExtension)}</span>
                                                                        </a>
                                                                        {/* IMAGE PREVIEW
                                                                    <img alt={additionalKey === "program" ? "Program" : "Deltagarlista"} src={`${API_URL}/file?id=${additionalValue}`} className={'img-fluid w-100 align-items-center bg-white'}/>
                                                                    </Col>
                                                                </Row>
                                                                : ''}
                                                            {/*{item.additionalFileExtension === "" && item.additionalInfo !== null && Object.keys(item.additionalInfo).length > 0 && additionalValue !== "" ?*/}
                                                            {/*    <Row>*/}
                                                            {/*        <Col lg={12}  className="text-start">*/}
                                                            {/*            {item.additionalInfo && item.additionalInfo.program ? item.additionalInfo.program : additionalValue}*/}

                                                            {/*        </Col>*/}
                                                            {/*    </Row>*/}
                                                            {/*    : ''}*/}
                                                            {/*
                                                            {item.additionalInfo && item.additionalInfo.program ?
                                                                <Row>
                                                                    <Col lg={12}  className="text-start">
                                                                        {item.additionalInfo.program }
                                                                    </Col>
                                                                </Row>

                                                                :''}

                                                        </Col>
                                                    </Row>*/}
                                                </Col>
                                                {item.status !== 'Förslag' ?
                                                    ''
                                                    :
                                                        editMode !== true ?
                                                            <Col xxl={3} className="d-flex align-items-center justify-content-end order-0 order-xxl-1">
                                                                <CustomButton text="" icon='bx bx-trash-alt py-1'
                                                                              iconColor='#EB5757'
                                                                              iconSize={5} buttonColor='#e9cbcd'
                                                                              buttonRadius={3} hoverButtonRadius={3}
                                                                              hoverIconColor='white'
                                                                              hoverButtonColor='#EB5757'
                                                                              customClasses="border-0"
                                                                              tooltipText={t('button.delete')}
                                                                              onClick={()=>{
                                                                                  onClickDelete(item, index)
                                                                              }}
                                                                />
                                                                <CustomButton text="" icon='bx bx-edit py-1'
                                                                              iconColor='#157553'
                                                                              iconSize={5} buttonColor='#bed1cd'
                                                                              buttonRadius={3} hoverButtonRadius={3}
                                                                              hoverIconColor='white'
                                                                              hoverButtonColor='#157553'
                                                                              customClasses="ms-3 border-0"
                                                                              tooltipText={t('button.edit')}
                                                                              onClick={() =>{
                                                                                  onClickEdit(item, index)
                                                                              }}/>
                                                            </Col>

                                                            : ''

                                                    }
                                            </Row>
                                        </Col>
                                    </Row>
                                    {item.status !== 'Förslag' ?
                                        <>
                                            {item.additionalInfo && item.additionalInfo.program ?
                                                <>
                                                    <div className="pt-3 row">
                                                        <span className="fw-bold col-4">{t('reimbursement.programInvitation')} </span>
                                                        <span className="col-8">{item.additionalInfo.program.split(/[\n,]+/).map((line, index2) => (
                                                            <React.Fragment key={index2}>
                                                                {line}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}</span>
                                                    </div>
                                                </>
                                            : ''}
                                        </>
                                    : ""}
                                </Card>
                            </Col>
                        )
                    }
                ))
                : ''}
        </>
    );

}