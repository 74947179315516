// src/authConfig.js
const response = await fetch('/branch-info.json');
const data = await response.json();
const currentBranch = data.branch;
export const msalConfig = {
    auth: {
        clientId: currentBranch === 'master' ?   "1be7e580-eed8-45c4-833b-40cc116ab65c" : "49e1831c-b97a-4ab8-8f52-19eec5515d2c",
        authority: `https://login.microsoftonline.com/cdd27c81-01cf-4287-a0a7-3807229f3498`,
        redirectUri: currentBranch === 'master' ? 'https://kassan.lrf.se/entralogin' : 'https://kassan-stage.lrf.se/entralogin',
        ValidateAuthority: true,
        postLogoutRedirectUri: currentBranch === 'master' ? 'https://kassan.lrf.se/entralogin' : 'https://kassan-stage.lrf.se/entralogin',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: false,
    }
};

let scope = [`api://6a77cbfb-24c7-4fb7-b754-cd569dfd69fc/API.Access`];
export const loginRequest = {
    scopes: scope,
};
