import React from 'react';
import {useTranslation} from "react-i18next";

export const FlashMessagePaymentDuplicated = ({jsonString}) => {
    const { t } = useTranslation();

    const json = JSON.parse(jsonString);

    return (
        <div>
            <div className="p-0 position-relative">
                <i id="closeButtonMessage" className="bx bx-x fs-4 position-absolute end-0 z-3" />
            </div>
            <table>
                <thead>
                    <tr>
                        <td colSpan="2" className="text-center border-0">
                            En annan användare har redan lagt till en eller flera betalningar i korgen. Vänligen kontrollera statusen för ärendet om några minuter..
                        </td>
                    </tr>
                    <tr>
                        <td className="border-bottom text-start">Id</td>
                        <td className="border-bottom text-start">Mottagare</td>
                    </tr>
                </thead>
                <tbody>
                    {json.map((item, index) => (
                        <tr key={index}>
                            <td className="border-0">{item.VisualId}</td>
                            <td className="border-0">{item.CreditorName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};