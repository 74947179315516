import i18n from 'i18next';
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class ResponseQRPayment{
    constructor({
                    id = '',
                    visualId = '',
                    amount = '',
                    comment = '',
                    callbackResponse = '',
                    createdAt = '',
                    updatedAt = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.amount = amount;
        this.comment = comment;
        this.callbackResponse = callbackResponse;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static getAttributes(filter = null) {
        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            amount:{
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                'sortField': 'amount',
                'thClass': 'ps-5',
                'insideClass' : (item)=> item.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                'value': (item) => item.amount ? ToCustomDecimal(item.amount) +' kr' : "",
            },
            comment :{
                'label': i18n.t('modelLabel.message'),
                'sortable': true,
                'sortField': 'comment',
                'value': (item) => item.comment || "",
            },
            provider :{
                'label': i18n.t('modelLabel.provider'),
                'sortable': true,
                'sortField': 'callbackResponse',
                'value': (item) => item.callbackResponse && item.callbackResponse.paymentOrder && item.callbackResponse.paymentOrder.paid && item.callbackResponse.paymentOrder.paid.instrument ? item.callbackResponse?.paymentOrder?.paid?.instrument : "",
            },
            method :{
                'label': i18n.t('modelLabel.method'),
                'sortable': true,
                'sortField': 'callbackResponse',
                'value': (item) => item.callbackResponse && item.callbackResponse.paymentOrder && item.callbackResponse.paymentOrder.paid && item.callbackResponse.paymentOrder.paid.instrument && item.callbackResponse.paymentOrder.paid.details ? (item.callbackResponse.paymentOrder.paid.instrument === "Swish" ? item.callbackResponse.paymentOrder.paid.details.msisdn : item.callbackResponse.paymentOrder.paid.details.maskedPan) : "",
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : '',
            },
            updatedAt: {
                'label': i18n.t('modelLabel.updatedAt'),
                'sortable': true,
                'sortField': 'updatedAt',
                'value': (item) => item.updatedAt ? ChangeDateFormat('dateTime', item.updatedAt) : '',
            },
        }
        return attributes;
    }
}
export default ResponseQRPayment;