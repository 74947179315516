import * as yup from "yup";
import {string} from "yup";
import i18n from "../../../AppSystem/i18n";
const response = await fetch('/branch-info.json');
const data = await response.json();
const currentBranch = data.branch;
class AdminRequestHonorariumCreate {
    constructor({
                    userId = '',
                    orgId = '',
                    costBearerId = '',
                    annualHonorarium = 0,
                    paymentInterval = 0,
                    firstPayment = '',
                } = {}) {
        this.userId = userId;
        this.orgId = orgId;
        this.costBearerId = costBearerId;
        this.annualHonorarium = annualHonorarium;
        this.paymentInterval = paymentInterval;
        this.firstPayment =  firstPayment;
    }

    static getAttributes(filter = null, validationSchema = null ,optionsData = null, customField = null) {

        const annualHonorarium = i18n.t('honorarium.annual');
        const yearly = i18n.t('modelLabel.yearly');
        const monthly = i18n.t('modelLabel.monthly');
        const daily = i18n.t('modelLabel.daily');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');

        const completeSchema = yup.object().shape({
            userId: string().required(fieldRequired),
            orgId: string().required(fieldRequired),
            costBearerId: string().required(fieldRequired),
            annualHonorarium: string().required(fieldRequired).test('is-positive',  `${i18n.t('validationMessage.greater0')}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            paymentInterval: string().required(fieldRequired),
            firstPayment: string().required(fieldRequired),
        });


        const basePaymentIntervalOptions = [
            { value: 1, label: yearly },
            { value: 12, label: monthly }
        ];

        const paymentIntervalOptions = currentBranch === 'develop'
        ? [
            ...basePaymentIntervalOptions,
            { value: 4, label: daily }
          ]
        : basePaymentIntervalOptions;

        let optionsUser = [];
        let optionsOrg = [];
        let optionsCost = [];
        if (optionsData) {
            optionsData.userId?.map(item => {
                optionsUser?.push({
                    label: item.firstName + ' ' + item.lastName,
                    value: item.id
                });
            })
            if (optionsUser) {
                optionsData.orgId?.map(item => {
                    optionsOrg?.push({
                        label: item.name  + ' ('+ item.type +')',
                        value: item.id
                    });
                })
            }
        }
        if (optionsData) {
            optionsData.costBearer?.map(item => {
                optionsCost?.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        // FORCE DATE TO BE 25TH OF THE MONTH
        const filterDate = (date) => {
            return date.getDate() === 25;
        };

        const attributes =  {
            userId :{
                'label': i18n.t('modelLabel.recipient'),
                'form': {
                    'type': 'select',
                    'options': optionsUser,
                    "search": true,
                    'default': '',
                    'placeholder': i18n.t('formPlaceholder.selectUserName'),
                    'validation': completeSchema.fields.userId,
                },
                'value': (item) => item.userId || "",
            },
            orgId: {
                'label': i18n.t('modelLabel.org'),
                'form': {
                    'type': 'select',
                    'options':optionsOrg,
                    "search": true,
                    'default':'',
                    'placeholder': i18n.t('formPlaceholder.selectOrg'),
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            },
            costBearerId: {
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options':optionsCost,
                    'default': '',
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            paymentInterval:{
                'label': i18n.t('honorarium.payment'),
                'form': {
                    'type': 'select',
                    'options': paymentIntervalOptions,
                    'default': '',
                    'validation': completeSchema.fields.paymentInterval,
                },
                'value': (item) => {
                    switch (item.paymentInterval) {
                        case "1":
                            return yearly;
                        case "12":
                            return monthly;
                        case "4":
                            return daily;
                        default:
                            return 'not set';
                    }
                },

            },
            annualHonorarium: {
                'label': annualHonorarium,
                'form': {
                    'type': 'number',
                    'placeholder': annualHonorarium,
                    'validation': completeSchema.fields.annualHonorarium,
                },
                'value': (item) => item.annualHonorarium || '',
            },
            firstPayment: {
                'label': i18n.t('honorarium.first'),
                'form': {
                    'type': 'date',
                    'placeholder': i18n.t('formPlaceholder.firstPayment'),
                    'validation': completeSchema.fields.firstPayment,
                    'filterDate': filterDate,
                },
                'value': (item) => item.firstPayment || '',
            }
        };

        if (validationSchema === true) {
            return completeSchema;
        }
        return attributes;
    }
}

export default AdminRequestHonorariumCreate;