import useApiCallService from "../../Common/Helpers/ApiCall";
import React, {useEffect, useState} from "react";
import SortableList from "../../Common/Components/Lists/SortableList";
import AdminResponseAttestModel from "../Model/Response/AdminResponseAttestModel";
import {Button, Col, Row} from "reactstrap";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AdminRequestAttestApprovedModel from "../Model/Request/AdminRequestAttestApprovedModel";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {useSelector} from "react-redux";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import AdminRequestAttestDenyModel from "../Model/Request/AdminRequestAttestDenyModel";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {FlashMessageInsufficientAccountAmount} from "../../Common/Helpers/FlashMessageInsufficientAccountAmount";

export function AdminAttestListPendingPrimary() {
    const { ApiCall } = useApiCallService();
    const { userData } = useSelector(state => state.auth);
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();

    // API REQUEST
    const [apiRequestAcceptAttest, setApiRequestAcceptAttest] = useState(new AdminRequestAttestApprovedModel());
    const [apiRequestDenyAttest, setApiRequestDenyAttest] = useState(new AdminRequestAttestDenyModel());

    //API RESPONSE
    //const [responseAttestList, setResponseAttestList] = useState(null);
    const [apiResponseAttest, setApiResponseAttest] = useState(null);
    const [apiResponseEmail, setApiResponseEmail] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const completeSchemaAccept = AdminRequestAttestApprovedModel.getAttributes( null, true);
    const completeSchemaDeny = AdminRequestAttestDenyModel.getAttributes( null, true);

    const [typeAttest, setTypeAttest] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [showConfirmModalApprove, setShowConfirmModalApprove] = useState(false);
    const [showConfirmModalDeny, setShowConfirmModalDeny] = useState(false);

    const [updateList, setUpdateList] = useState(0);

    const [errorReason, setErrorReason] = useState(false);

    let permissionUpdate = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.attest") || CheckUserPermission("admin.attest.approve")) {
        permissionUpdate = true;
    }

    let permissionDeny = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.attest") || CheckUserPermission("admin.attest.deny")) {
        permissionDeny = true;
    }

    //updates the component to show when you have accepted or denied attest, it may launch several calls to the api
   /* useEffect(() => {
        setUpdateList(updateList + 1)
    }, [apiResponseAttest]);
    */

    // PUT APPROVED
    const handleValidation = async (type) => {
        try {
            if (type === "approve") {
                await completeSchemaAccept.validate(apiRequestAcceptAttest, { abortEarly: false });
            } else {
                await completeSchemaDeny.validate(apiRequestDenyAttest, { abortEarly: false });
            }
            const apiCallOptions = {
                method: type === "approve" ? "put" : "delete",
                url: "/attest/admin",
                data: type === "approve" ? apiRequestAcceptAttest : apiRequestDenyAttest,
            };
            setTypeAttest(type);
            try {
                await ApiCall(apiCallOptions, setApiResponseAttest, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    //button.disabled = true;
                    //button.classList.add('disabled');
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab');
                    }
                });
            }
        } catch (err) {
            setErrorReason(true);
            console.log(err)
        }
    }

    // PUT APPROVED
    const SendEmailNotifications = async () => {
        try {
            setIsButtonDisabled(true);
            const apiCallOptions = {
                method: "get",
                url: "/email/admin",
            };
            try {
                ApiCall(apiCallOptions, setApiResponseEmail, setErrorMessage);  
                FlashMessage('Förfrågan har behandlats, denna process kan ta upp till 1 minut (eller mer), efter det kontrollera din e-post. ', 'flash-messageSuccess');
                setIsButtonDisabled(false);
                setTimeout(()=>{
                    CustomReload();
                },4000);           
            } catch (error) {
                console.log(error);
                FlashMessage('Det uppstod ett problem med att skicka meddelanden', 'flash-messageSuccess');
                setIsButtonDisabled(false);
            }
        } catch (err) {
            setErrorReason(true);
            console.log(err)
        }
    }

    // HANDLE APPROVE
    const handleChangeAttestApprove = (item) => {
        setApiRequestAcceptAttest((prevFormData) => ({
            ...prevFormData,
            id: item.id,
            attested: true,
        }))
    }

    // HANDLE DENY
    const handleChangeAttestDenyId = (id) => {
        setApiRequestDenyAttest((prevFormData) => ({
            ...prevFormData,
            id: id,
        }))
    }

    // HANDLE DENY REASON
    const handleChangeAttestDenyReason = (e) => {
        if (e && e.target && e.target.value && e.target.value !== "") {
            setApiRequestDenyAttest((prevFormData) => ({
                ...prevFormData,
                reason: e.target.value,
            }))
            setErrorReason(false);
        } else {
            setErrorReason(true);
        }
    }

    const renderActionColumn = (item) => {
        return (
            <>
                {item.createdBy !== userData?.id && item.status === "Skickat" && permissionUpdate ?
                    /*<CustomButton icon="bx bx-list-check pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4} buttonColor="#cde0da"  tooltipText={t('attest.approve') } customClasses="rounded me-2 py-2" buttonBorder={false} id={item.id} onClick={() => {
                        handleChangeAttestApprove(item);
                        setShowConfirmModalApprove(true);
                    }} />*/
                    <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                            onClick={() => {
                                handleChangeAttestApprove(item);
                                setShowConfirmModalApprove(true);
                                const allButtons = document.querySelectorAll('button');
                                // Itera sobre cada botón y lo deshabilita
                                allButtons.forEach(button => {
                                    //button.disabled = true;
                                    //button.classList.add('disabled');
                                    if (button.classList.contains('class-approve')) {
                                        button.disabled = true;
                                        button.classList.remove('bg-green-light', 'text-green');
                                        button.classList.add('bg-grey-tab');
                                    } else if (button.classList.contains('class-deny')) {
                                        button.disabled = true;
                                        button.classList.remove('box-red', 'text-red');
                                        button.classList.add('bg-grey-tab');
                                    }
                                });
                            }} >
                        <i className="bx bx-list-check pb-custom align-middle size-icon-custom-button" />
                        <div className="tooltip-custom-button">
                            {t('attest.approve') }
                        </div>
                    </button>
                : ''}
                {item.createdBy !== userData?.id && item.status === "Skickat" && permissionDeny ?
                    /*<CustomButton icon="bx bx-list-minus pb-custom" iconColor="#F2495C" hoverIconColor='white' iconSize={4} tooltipText={t('attest.deny') } customClasses="rounded me-2 box-red py-2" buttonBorder={false} id={item.id} onClick={() => {
                        handleChangeAttestDenyId(item.id);
                        setShowConfirmModalDeny(true);
                    }} />*/

                    <button id={item.id} className="rounded me-2 py-2 border-0 text-red box-red p-custom-button position-relative class-deny"
                            onClick={() => {
                                handleChangeAttestDenyId(item.id);
                                setShowConfirmModalDeny(true);
                                const allButtons = document.querySelectorAll('button');
                                // Itera sobre cada botón y lo deshabilita
                                allButtons.forEach(button => {
                                    if (button.classList.contains('class-approve')) {
                                        button.disabled = true;
                                        button.classList.remove('bg-green-light', 'text-green');
                                        button.classList.add('bg-grey-tab');
                                    } else if (button.classList.contains('class-deny')) {
                                        button.disabled = true;
                                        button.classList.remove('box-red', 'text-red');
                                        button.classList.add('bg-grey-tab');
                                    }
                                });
                            }} >
                        <i className="bx bx-list-minus pb-custom align-middle size-icon-custom-button" />
                        <div className="tooltip-custom-button">
                            {t('attest.deny') }
                        </div>
                    </button>

                : ''}
                {/*<CustomButton icon="bx bx-show pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded py-2 class-approve" buttonBorder={false} id={item.id} onClick={() => {
                     navigate(`${ADMIN_PATHS.ATTEST_INFO}?id=${item.id}`);
                }} />*/}
                <button className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.ATTEST_INFO}?id=${item.id}&from=primary`);
                        }} >
                    <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('button.info') }
                    </div>
                </button>
            </>
        );
    }

    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const columns = [
        'createdAt',
        "recipient",
        "orgName",
        "costPlace",
        "model",
        "modelVisualId",
        "modelStartDate",
        "amount",
        //"status",
    ];

    if (isSuperAdmin === true) {
        columns.unshift('id');
    }
    const ignoreFields = ["Id", "OrgId", "ModelId", "CreatedBy"];
    const listOptions = {
        "model": AdminResponseAttestModel,
        'columns':columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/attest/admin/search-pending-cost-place",
        "nameFile": t('attest.attest'),
        "modelType" : "Attest",
        "ignoreFields" : [...columns, "createdByName"],
        "urlGetSearch" : "/attest/admin/search-pending-cost-place",
    };


    const contentBodyApproved = (
        <div>{t('validationMessage.modalAttest')}</div>

    );
    const contentFooterApproved = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await handleValidation("approve");
            }} >{t('button.accept')}</Button>
            <Button className="box-red border-0" onClick={() =>{
                setShowConfirmModalApprove(false);
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    //button.disabled = true;
                    //button.classList.add('disabled');
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab');
                    }
                });
            }}>{t('button.cancel')}</Button>
        </>
    );

    const contentBodyDeny = (
        <>
            <div className="text-center pb-3">
                <h5 className="rokkitt-font">{t('validationMessage.modalAttestDenyHeader')}</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-75 position-relative">
                    <CustomInput model={AdminRequestAttestDenyModel} attribute="reason" customChange={e => handleChangeAttestDenyReason(e)}/>
                    <div className={`error tooltip-custom-normal ${errorReason === true ? 'opacity-1' : 'opacity-0'}`}
                        style={{color: '#f56e6e'}}>
                        {t('validationMessage.fieldRequired')}
                    </div>
                </div>
            </div>
            <div>{t('validationMessage.modalAttestDeny')}</div>
        </>
    );

    const contentFooterDeny = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await handleValidation("deny");
            }}>{t('button.accept')}</Button>
            <Button className="box-red border-0" onClick={() => {
                setShowConfirmModalDeny(false);
                setErrorReason(false);
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab');
                    }
                });
            }}>{t('button.cancel')}</Button>
        </>
    );

    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (apiResponseAttest !== null && typeAttest !== '') {
            if (typeAttest === "approve") {
                FlashMessage(t('validationMessage.attestApproved'), 'flash-messageSuccess');
                setShowConfirmModalApprove(false);
            } else {
                setShowConfirmModalDeny(false);
                FlashMessage(t('validationMessage.attestDenied'), 'flash-messageSuccess');
            }
            setTimeout(()=>{
                CustomReload();
            },2000);
        }
    }, [apiResponseAttest]);

    // Notifies the user that have pending attest by approved or denied
    // useEffect(() => {
    //     if (apiResponseEmail !== null) {
    //         FlashMessage('Användarna har informerats via e-post', 'flash-messageSuccess');
    //         setIsButtonDisabled(false);
    //         setTimeout(()=>{
    //             CustomReload();
    //         },2000);
    //     }
    // }, [apiResponseEmail]);

    //Manage the different error messages

    useEffect(() => {
        if (errorMessage !== '') {
            if (errorMessage.includes("{")) {
                FlashMessage(<FlashMessageInsufficientAccountAmount jsonString={errorMessage} />, 'flash-messageError', 10000, true);
            }
            else {
                if (errorMessage === 'InsufficientBalance') {
                                    FlashMessage(t('validationMessage.insufficientBalance'), 'flash-messageError');
                }              
                else if (errorMessage === 'UserNeedBankAccount') {
                    FlashMessage(t('validationMessage.notAccount'), 'flash-messageError');
                }
                else if (errorMessage === 'Conflict') {
                    FlashMessage(t('En annan användare har redan påbörjat attest av detta ärende. Kontrollera ärendets status.'), 'flash-messageError', 5000,);
                }
                else if (errorMessage === 'AttestNotFound') {
                    FlashMessage(t('En annan användare har redan påbörjat attest av detta ärende. Kontrollera ärendets status.'), 'flash-messageError', 5000);
                }   
                else if (errorMessage === 'Det gick inte att generera ett unikt tillfälligt fakturanummer efter flera försök, försök igen.') {
                    FlashMessage(errorMessage, 'flash-messageError');
                }
                else{
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
            setTimeout(()=>{
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    //button.disabled = true;
                    //button.classList.add('disabled');
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab');
                    }
                });
            },2000)
            setApiResponseAttest(null);
            if (errorMessage === 'Conflict' || errorMessage === 'AttestNotFound') {
                setErrorMessage('');
                setTimeout(()=>{CustomReload();},5200);
            }
            setErrorMessage('');
            
        }
    }, [errorMessage]);

    return <>
        <Col lg={12} className='card card-backend p-4 mb-5'>
            <CustomModal isOpen={showConfirmModalApprove} toggle={() => setShowConfirmModalApprove(!showConfirmModalApprove)} body={contentBodyApproved} footer={contentFooterApproved}/>
            <CustomModal isOpen={showConfirmModalDeny} toggle={() => setShowConfirmModalDeny(!showConfirmModalDeny)} body={contentBodyDeny} footer={contentFooterDeny} />
            <Row>
                <Col lg={4}>
                    <h2 className='rokkitt-font'>{t('attest.labelListPendingPrimary')}</h2>
                </Col>
                <Col lg={5} className="text-center">
                    <InfoPageMessage message={t('infoBox.attestPrimary')}/>
                </Col>
                <Col lg={3} className="text-end">
                    <Button className="bg-green mb-2" onClick={() => {
                        navigate(`${ADMIN_PATHS.ATTEST_LIST_PENDING}`);
                    }}>
                        {t('openPayment.otherItems')}
                    </Button>
                    <Button className="bg-green ms-3 mb-2" onClick={() => {
                        navigate(`${ADMIN_PATHS.ATTEST_LIST_HISTORY}`);
                    }}>
                        {t('openPayment.history')}
                    </Button>
                    <Button className="bg-green ms-3 mb-2" disabled={isButtonDisabled} onClick={() => {
                       SendEmailNotifications();
                    }}>
                        Skicka e-post
                    </Button>
                </Col>
            </Row>
            <span className=""></span>
                <SortableList
                   // key={updateList}
                    listOptions={listOptions}
                />
        </Col>
    </>


}