export const eng = {

  footer: {
    text: 'Design & Develop by Trust Anchor Group',
  },
  userNotifications: {
    notifications: 'Notifications',
    text: 'Mark all as read',
  },
  roterLogin:{
    buttonBack: 'Back to Test Account',
    spinner: 'Loading user data...',
    loginQR: 'Login QR'
  },
  notificationsDropdown:{
    notifications: 'Notifications',
    text: 'Mark all as read',
    icon: 'View More...',
  },
  sortableList:{
    search: 'Search',
    buttonTable: 'Table',
    buttonGrid: 'Grid',
    loading: 'Loading...',
    itemsPage: 'items per page',
    noResults: 'No results found.',
    apiErrorResult: 'It was not possible to connect with the API', //Getting data
    gettingData:'Getting data...',
    exportData: 'Export ',
    all:'All items'
  },
  reportProceeding:{
    calculations:{
      title: 'Calculations',
      subtitle: 'Development',
      salary: 'Salary',
      gross: 'Gross',
      hours: 'Hours * Hourly Salary or Fixed',
      mileage: 'Mileage',
      taxFree:{
        title: 'Tax Free:',
        text: 'Kilometers / 10 * Deductible Travel Per 10Km',
      },
      taxable: {
        title: 'Taxable:',
        text: 'Kilometers kr + Total Passenger KM kr - Tax Free',
      },
      incomeTax:{
        title: 'Income Tax:',
        text: 'Taxable * Income tax % / 100',
        textGross: 'Gross * Income tax % / 100'
      },
      net:{
        title: 'Net:',
        text: 'Tax Free + Taxable - Income Tax',
      },
      employmentTax: {
        title: 'Employment Tax:',
        text: 'Taxable * Employment Tax % / 100',
        textGross: 'Gross * Employment Tax % / 100'
      },
      expenses: 'Expenses',
      amount: 'Amount:',
      amountWithout: 'Amount without VAT:',
      tax: 'Tax',
      total: 'Total',
      totalText: 'Amount + Tax',
      amountTotal: 'Amount - Tax'
    }
  },
  ////////////////////////////////////////////////////////////
  payReceive: {
    payReceiveTitle: 'Pay Receive',
    payReceive: 'Pay/Receive',
    pay: 'Pay',
    quickPay: 'Pay',
    bankAccount: 'Bank account',
    bankgiro: 'Bankgiro',
    plusgiro: 'Plusgiro',
    selectPayment: 'Select the payment method and fill in the payment details',
    payment:'Payment',
    transfers: 'Transfers',
    sign: 'Sign for payment',
    // buttonPay: 'Pay Invoice',
    // buttonManagement: 'Manage Expenses',
    // buttonTransfer: 'Direct transfer',
    quickPayment: 'Quick Payment',
    quickPayments: 'Quick Payments',
    quickPaymentSubtitle: 'Fill in the payment details',
    //temporalAlert:'This page is visual only, it has no functionality',
    payInvoice:'Pay Invoice',
    paymentType: 'Payment Type',
    email: 'Email',
    description: 'Description',
    amountQuickPayment: 'Amount',
    infoEmail: 'Type or paste the e-mail addresses of the recipients. Addresses can be separated by a new line, a space, a comma or a semicolon.',
  },
  payDirectTransmission:{
   transfer: 'Direct transfer',
    paymentRefers: 'Payment refers to*',
    number: 'Clearing number*',
    accountNumber: 'Account number*',
    notes: 'Notes*',
    amount: 'Amount*',
    //save: 'Save in notes',
    //sign: 'Sign for payment',
    second: 'Second Tab',
    managing: 'Manage invoices',
    selectInvoice: 'Select an invoice from the list to manage payments'
  },
 /* payExpenses:{
    notifications: 'Notifications',
    number: 'Number of overdue payments',
    unpaid: 'Unpaid expenses',
    from: 'From',
    paid: 'Paid expenses',
    add: 'Add expenses',
    new: 'New receipt',
    manage: 'Manage invoice',
    select: 'Select an invoice from the list to manage payments'
  },
  payInvoice:{
    notifications: 'Notifications',
    number: 'Number of overdue payments',
    unpaid: 'Unpaid invoices',
    date: 'Due date',
    paid: 'Paid invoices',
    add: 'Add invoice',
    new: 'New invoice',
    download: 'Download documents',
    sign: 'Sign for payment',
    manage: 'Manage invoice',
    select: 'Select an invoice from the list to manage payments'
  },*/
/////////////////////New///////////////////////////
  modelLabel: {
    id: 'Id',
    org:'Organization',
    orgId: 'Organization Id',
    orgName:'Organization Name',
    orgType:'Organization Type',
    orgDestination:'Destination Organization',
    costPlace: 'Cost Place',
    orgDetails:'Organization Details',
    orgNumber:'Organization number',
    createdAt:'Created at',
    createdBy:'Created by',
    creatorName:'Creator Name',
    attestAmount:'Attest Amount',
    updatedBy:'Updated by',
    updatedAt: 'Updated at',
    updatedByName: 'Updated by (Name)',
    modelId:'Model Id',
    user: 'User',
    userId: 'User Id',
    userName: 'User Name',
    name:'Name',
    parentId: 'Parent Id',
    parentName: 'Parent Name',
    fullName: 'Full Name ',
    firstName: 'First Name',
    lastName: 'Last Name',
    info: 'Information',
    country: 'Country ',
    sweden: 'Sweden',
    pnr: 'PNR',
    email: 'Email',
    phone: 'Phone',
    employmentTax: 'Employment Tax',
    incomeTax: 'Income Tax',
    net:'Net',
    date:'Date',
    dateReimbursement:'Activity date',
    dateReceive: 'Date Receive',
    status:'Status',
    amount:'Amount',
    amountInvoice:'Amount',
    addAmount: 'Add Amount', ///,mirar si esta traduccion se usa en algun sitio, si no borrarla
    type:'Type',
    typeId:'Type Id',
    types:'Types',
    vatAmount: 'VAT Amount',
    vatAmountInvoice: 'VAT Amount',
    accountingId:'Accounting Id',
    accounting:'Accounting',
    fileId: 'File Id',
    fileName:'File Name',
    file: 'File',
    files: 'Files',
    additionalFileId: 'Additional File Id',
    additionalFileName:'Additional File Name',
    kr: 'kr',
    km: 'km',
    hours: 'Hours',
    kilometers: 'Kilometres',
    total: 'Total',
    totalAmount: 'Total Amount',
    welcome: 'Welcome',
    profile: 'Profile',
    logOut: 'Log Out',
    paid: 'Paid',
    unpaid: 'Unpaid',
    yearly: 'Yearly',
    monthly: 'Monthly',
    description:'Description',
    model:'Model',
    balance: 'Balance',
    currency:'Currency',
    from:'From',
    to:'To',
    added:'Added',
    grossAmount:'Gross Amount',
    soon: 'As soon as possible',
    whenToPay: 'When to pay',
    reason: "Reason",
    creditorName: 'Creditor Name',
    forYou: 'For you',
    forYourOrganization: 'For your organization',
    userSalary: 'User Salary',
    initialized: 'Initialized',
    aborted: 'Aborted',
    message: 'Message',
    recipient: "Recipient",
    paymentMethod: "Payment method",
    transactionDate: "Transaction date",
    approvedDeniedByName: "Supervised by",
    approvedDeniedAt: "Supervised at",
    generated: "Generated",
    salarySheet: "Salary Sheet",
    active: "Active",
    deleted: "Deleted",
    taxAuthority: "Reports to the Tax Authority",
    lastRunAt: 'Last run at',
    paymentType: 'Payment Type',
    credited:'Credited',
    revoked: 'Revoked',
    attestQueue: 'AttestQueue',
    TheSwedishTaxAgency: 'The Swedish Tax Agency',
    comment:'Comment',
    download: 'Download',
    downloading: 'Downloading...',
    transaction: 'Open Payment Transactions',
    bookedDate: 'Booked Date',
    balanceAfterTransaction: 'Balance After Transaction',
    openPaymentAccountId: 'Open Payment Account ID',
    openPaymentTransactionId: 'Open Payment Transaction ID',
    openPaymentStatus: 'Open Payment Status',
    transactionListId: 'Transaction List ID',
    updateTransactions: 'Update Transactions',
    consent: 'Consent',
    consentCode: 'Consent Code',
    invoiceNumber: 'Invoice Number',
    paidOut: 'Paid Out',
    sentAt: 'Sent at',
    daily: 'Daily (Test)',
    collect: 'Collect',
    reports:'Reports',
    totalDistance: 'Total distance (km)',
    refresh: 'Refresh',
    refreshExpired: 'Your QR code has expired',
    provider: 'Payment methods',
    method: 'Number',
    canceled: 'Canceled',
    fromDateReimbursement: 'From date',
    toDateReimbursement: 'To date',
    multipleDayEvent: 'Multiple day event',
    KST: 'KST',
    header: 'Header',
    startDate: 'Type start date',
  },
  adminLabel: {
    admin:'Admin',
    organizations: 'Admin Organizations',
    details:'Organization Admin Details',
    users:'Admin Users',
    systemRoles: 'System Roles',
    orgUser: 'Organization User',
    orgUsers:'Organization Users',
    orgRoles: 'Organization Roles',
    digitalAccount: 'Admin digital Account',
    systemAdmin: 'System Admin',
    system:'System',
    allAccount: 'All Account',
    assignRoles: "Assign Roles",
    assignOrg: "Assign Organization",
    userLoggedOnLastTime: 'Logged on last time',
    assignCostPlaces: 'Primary Cost Places',
  },
  button:{
    back: 'Back',
    cancel: 'Cancel',
    delete: 'Delete',
    send: 'Send',
    sendToAttest: 'Send to attest',
    sendAndCreateCopy: 'Send and create copy',
    save: 'Save',
    loading: 'Loading...',
    edit: "Edit",
    info: 'Info',
    accept:'Accept',
    continue:'Continue',
    moreInfo:'More info',
    hideInfo:'Hide info',
    create: 'Create',
    assign: "Assign",
    active: 'Active',
    renew: 'Renew',
    authorize: 'Authorize',
    home: "Home",
    close: "Close window",
    saveReimbursement: 'Save draft',
  },
  validationMessage:{
    sendError: 'An error has occurred, please try again later or contact LRF',
    sendErrorShort: 'An error has occurred',
    sendSuccessfully: 'Data has been sent successfully',
    fileDeleted: 'File Deleted Succesfully',
    fileFormatIncorrect: 'The file format is incorrect',
    fileFormatCorrect: 'The file format is correct',
    sendForm: 'Your data is being sent, please wait...',
    modalVar: 'Are you sure you want to delete',
    modalImage: 'Are you sure you want to delete this image?',
    modalItem: 'Are you sure you want to delete this item?',
    modalAmount: 'Are you sure you want to delete this amount?',
    fieldRequired: 'Field required',
    validOption: 'Please select a valid option',
    greater0: 'The value must be greater than 0',
    digits4: 'The digits must be greater than 4',
    digits7: 'The digits must be greater than 7',
    digits2: 'The digits must be greater than 2',
    max12: 'The digits max is 12',
    max140: 'Max 140 characters',
    max40: 'Max 40 characters',
    between05and24: 'The value must be an integer between 0 and 24',
    minDigit4: 'The number must be at least 4 digits',
    vatLessAmount: 'VAT must be less than the amount',
    fileDeletedImportant: 'IMPORTANT: If the image is deleted, when you click cancel you will not have the image again',
    wrong: 'Something went wrong', //mirar si este mensaje se va a usar o no, por que es para el sortablelist
    changeNotSave:' You have changes that are not saved. Are you sure you want to leave?',
    reimbursementSuccessfully:'Report Proceeding has been created successfully',
    reimbursementSavedSuccessfully:'Report Proceeding has been saved successfully',
    internalTransactionSuccessfully:'Internal Transaction has been created successfully',
    honorariumSuccessfully:'Honorarium has been created successfully',
    honorariumUpdate:'Honorarium has been updated correctly',
    bankAccountSave:'Bank account has been saved successfully',
    bankAccountUpdate:'Bank account has been updated correctly',
    attestApproved:'Attest has been successfully approved',
    attestDenied:'Attest has been successfully denied',
    paymentDenied:'Payment has been successfully denied',
    roleSuccessfully:'Role has been created successfully',
    permissionUpdate:'Permissions has been updated correctly',
    typeSuccessfully:'Type has been created successfully',
    notAccount:'The Attest user does not have an associated bank account',
    insufficientBalance:'The Organization Account have not enough funds',
    modalAttest:'Are you sure you want to approve this attest?',
    modalAttestDeny:'Are you sure you want to deny this attest?',
    modalAttestDenyHeader:'Deny attest',
    modalPaymentDeny:'Are you sure you want to deny this Payment?',
    modalPaymentDenyHeader:'Deny Payment',
    canNotBeEmpty: "Field can not be empty",
    paySuccessfully: 'Pay has been created successfully',
    selectPayment: 'You must select a payment',
    sendReimbursement:'Do you really want to send in the Proceeding?',
    saveReimbursement:'Do you really want to save the Proceeding?',
    sendReimbursementNotSaved: "You have unsaved data, do you want to submit the Proceeding?",
    sendReimbursementNotSavedSave: "You have unsaved data, do you want to save the Proceeding?",
    organizationNotMoney: 'The Organization does not have enough funds',
    sameOrg: 'Organizations cannot be the same',
    payAborted: 'Payment was successfully aborted',
    needAccount: 'You need to have an account to send a proceeding.',
    goAddAccount: 'Go to add account',
    invalidEmail: 'The e-mail must contain at least one @ and a valid domain (e.g. example@domain.com).',
    deleteUser: 'Are you sure you want to delete this user?',
    activeUser: 'Are you sure you want to activate this user?',
    client: 'Client has been created successfully',
    invoiceSave: 'Invoice has been saved successfully',
    invoiceSend: 'Invoice has been send successfully',
    invoiceSendToAttest: 'Invoice has been sent for attest',
    needSave: 'The invoice must be saved',
    QRCode:'The QR has been created successfully',
    clientPermission: 'You need client permission to view this field',
    loadingPayment:'Loading payment method',
    dontLetter:'Only numbers allowed',
    onlyNumbers: 'Incorrect number format, please use whole numbers or numbers with up to two decimal places.',
    creditInvoice :'Credit Invoice was created successfully',
    sendCreditInvoice:'Do you really want to create an invoice credit?',
    changeStatus:'Are you sure you want to change the status to paid?',
    consentExpired: 'The consent has expired',
    consentNotFound: 'The consent was not found',
    accountIdNotValid: 'The account is not valid',
    serviceUnavailableTryAgain: 'Service not available, please try again later',
    renewConsentSuccessfully: "Consent has been renewed successfully",
    consentAuthorizationPage: "Consent authorization page will be opened soon",
    activeConsentSuccessfully: "Consent has been activated successfully",
    updateTransactionsSuccessfully: "Transactions have been updated successfully",
    consentRejected: "Consent has been rejected",
    sendingReimbursement: "Sending reimbursement, please wait...",
    savingReimbursement: "Saving reimbursement, please wait...",
    sendingPay: "Sending payment, please wait...",
    savingClient: "Saving client, please wait...",
    sendingInvoice: "Sending invoice, please wait...",
    savingInvoice: "Saving invoice, please wait...",
    sendingQr: "Sending QR, please wait...",
    sendingInternalTransaction: "Sending internal transaction, please wait...",
    sendingHonorarium: "Sending honorarium, please wait...",
    loadingSieData: "Loading SIE data, please wait...",
    assigningRoles: "Assigning roles, please wait...",
    assigningOrgs: "Assigning organizations, please wait...",
    sendingConsent: "Sending consent, please wait...",
    deleteFixed: "Are you sure you want to delete salary?",
    deleteMileage: "Are you sure you want to delete mileage?",
    deleteExpense: "Are you sure you want to delete expense?",
    consentIsValidated: "Consent is validated",
    consentIsValidating: "Consent is validating",
    selectAmountType: "It is necessary to select a amount type",
    dateMustBeLessThanToDate: "The date must be less than the to date",
    totalHourMustBeMoreThan0: "The total hours must be more than 0",
    amountMustBeMoreThan0: "The amount must be more than 0",
    totalAmountMustBeMoreThan0: "The total amount must be more than 0",
    assigningCostPlaces: "Assigning cost places, please wait...",
  },
  formPlaceholder: {
    selectDate: 'Select date',
    selectOption: 'Select an option',
    datePicker: 'from date - to date',
    enterRole: 'Enter Role',
    enterPermissions: 'Enter permissions',
    enterType: 'Enter Type',
    enterQuantity: 'Enter quantity',
    enterKm: 'Enter Km',
    enterAmount: 'Enter amount',
    dragAndDrop: 'Drag and drop',
    enterDate:'Enter date',
    enterDateReimbursement:'Enter the date of the activity',
    enterPurpose:'Enter purpose',
    enterVatAmount: 'Enter VAT amount',
    enterHours: 'Enter hours',
    enterOrigin: 'Enter origin',
    enterDeparture: 'Enter place of departure',
    enterDestination: 'Enter destination',
    enterPassengers: 'Enter passengers',
    enterTotalAmount: 'Enter total amount',
    selectUserName: 'Select User Name',
    selectOrg:'Select Organization',
    firstPayment:'Date of first payment',
    modelEnterClearingNumber:'Enter Clearing Number',
    modelEnterAccountNumber:'Enter Account Number',
    reason: 'Enter reason',
    digits: "digits",
    enterCreditorName:'Enter creditor name',
    selectClientName: 'Select client name',
    enterRecipient: 'Enter recipient reference',
    enterReference: 'Enter reference',
    enterPrice: 'Enter price',
    enterClientName: 'Enter client name',
    enterOrgNumber: 'Enter Organization number',
    enterAddress: 'Enter address',
    enterPostalCode: 'Enter postal code',
    enterCity: 'Enter city',
    enterEmail: 'Enter email',
    enterTitle: 'Enter title',
    enterQRName: 'Enter, for example, names that are only visible internally',
    enterDescription: 'Enter description',
    enterComment: 'Enter comment'

  },
  reimbursement: {
    reportProcessing: 'Report Proceeding',
    reportProcessings: 'Report Proceedings',
    newProcessing: 'New Proceeding',
    sendInfo: "To send the report proceedings must contain either Salary, Mileage or Expense",
    salary: 'Salary',
    hourly: 'Hourly',
    movable: 'Movable',
    fixed: 'Fixed',
    addSalary: 'Add salary',
    purpose: 'Purpose',
    costBearer: 'Cost Bearer',
    costBearerName: 'Cost Bearer Name',// quitarlo si no hace falta
    expense:'Expense',
    expenseTitle:'Expense',
    addExpense: 'Add expense',
    mileageCompensation: 'Mileage compensation',
    mileage:'Mileage',
    addMileageCompensation: 'Add mileage compensation',
    passengers: 'Passengers',
    numberPassengers: 'Number of passengers',
    addCompensation: 'Add Compensation',
    addPassengers: 'Add fellow passengers',
    addMileage: 'Add Mileage',
    cancelPassenger: 'Cancel Passenger',
    origin: 'Origin',
    destination: 'Destination',
    receipt: 'Receipt',
    programInvitation: 'Program or Invitation',
    listParticipants: 'List of Participants',
    amount:'Amount (VAT included)',
    addReceipts:'Add Receipts',
    newReimbursementId: "Process Report Duplicate Reference",
    deniedMessage: "This Report Proceeding was denied. You can create a duplicate and edit it",
    deniedMessageCreate: "Previous Report Proceeding was denied",
    sent: 'Sent',
    draft: 'Draft',
    passengersHelpText: 'Add up the distances travelled by each co-passenger and enter',
  },
  honorarium: {
    honorarium:'Honorarium',
    update: 'Honorarium Update',
    create: 'Create Honorarium',
    annual: 'Annual Honorarium',
    payment: 'Payment Interval',
    first: 'First Payment',
    queued: 'Queued Payments',
    newHonorarium: 'New Honorarium'
  },
  role:{
    userRole:'User Role',
    orgRole:'Organization Role',
    orgRoles:'Organization Roles',
    orgType:'Organization Type',
    roles:'Roles',
    permissions:'Permissions',
    infoAlert:' Remember to press enter to insert the permissions',
    addRole: 'Add Role',
    addType:'Add Type',
    role:'Role',
    theseAreThePermissions: 'These are the possible permissions',
    explanation: 'If you select first word, you have full permission. ("attest" is like "attest.approve" and "attest.view"). If you add the permissions "create","update", "approve","deny" individually, you must also select the "view".'
  },
  userSetting: {
    settings:'Settings',
    bankAccount:'Bank Account',
    modelClearingNumber:'Clearing Number',
    modelAccountNumber:'Account Number',

  },
  digitalAccount:{
    label: 'Digital Account',
    list:'Account List',
    accountRowList:'Account row List',
    accountRowInfo:'Account row Info',
    swedbankFee: 'Swedbank Fee',
    totalBalance: 'Total Digital Accounts Balance',
    totalBalanceSearch: 'Total balance on searched accounts',
  },
  attest: {
    attest:'Attest',
    labelListPending:'Current Pending Attests',
    labelListPendingPrimary:'My Pending Attests',
    labelListHistory:'Attest List History',
    labelInfo:'Attest Info',
    attested: 'Attested',
    approved:'Approved',
    pending:'Pending',
    approve:'Approve',
    deny: "Deny",
    denied: "Denied",
    deniedBy: 'Denied by',
    paymentInformation: 'Payment Information',
    recipientAccount: 'Recipient account',
    paidDateTime: 'Payment date',
    notEnoughFunds: 'Not enough funds',
    approvedButNotPaidOut: 'Approved but not paid out',
    thisProceeding: 'This proceeding',
    missingFunds: 'Missing funds',
    balanceAccount: 'Balance account',
  },
  dashboard: {
    dashboard: 'Dashboard',
    bodyTitle: 'Thank you for joining us in testing our new platform!',
    bodyText: 'Thank you for taking the time to test. Your feedback is important for us to fix any bugs and improve the experience.',
  },
  exportSieFile: {
    label:'Export SIE',
    moddelExportDocument:'Do you want to preview the file or export directly ?',
    modelPreview:'Preview',
    modelErrorMessageExport:'There is no information to export in this date range.',
    periodNotFound: 'There is no data in this time period',
  },
  openPayment: {
    creditorName: 'Creditor Name',
    creditorAccountIban: 'Creditor Accounting Iban',
    creditorAccountBban: 'Creditor Accounting Bban',
    bankTransfers: 'Bank Transfers',
    pendingBankList:'Pending Bank Transfer List',
    historyBankList: 'Bank Transfer History',
    history: 'History',
    bulkPayment: 'Bulk payment',
    sign:'Sign',
    instructedAmount:'Instructed Amount',
    activeConsentCode: 'Your active consent code is',
    consentCodeNotFound: 'Consent code not found',
    infoMessage: `Steps to Authorize and Activate a Consent session:\n 1. Click "Renew" button for create/renew a Consent if do not have one or it has expired (1st step).\n 2. After clicking on the "Authorize" button (2nd step), a page will open to verify the user with BankId or similar, and finally you will be redirected to a page where consent will be active.\n 3. When you have finished these steps, you can click the “Update Transactions” button.`,
    infoBankTransfer:'Information about bank transfer',
    myItems: 'My Items',
    otherItems: 'Current Attests',
  },
  internalTransaction: {
    internalTransaction: 'Internal Transaction',
    create: 'Create Internal Transaction',
    from:'From',
    to:'To',
  },
  accessibility:{
    selectLanguage:'Language selector',
    logo:'Kassan logo you can go to dashboard from this link',
    goFirst:'go to the first page',
    goLast:'go to the last page',
    prevPage:'previous page',
    backPage: 'next page',
    userOptions:'user options',
    registered:'registered',
  },
  invoice: {
    invoice: 'Invoice',
    creditInvoice: 'Credit Invoice',
    creditInvoiceRef: 'Credit Invoice Reference',
    createInvoice: 'Create Invoice',
    createCreditInvoice: 'Create Credit Invoice',
    clientName: 'Client Name',
    invoiceList: 'Invoice List',
    addClient:'Add Client',
    billingAddress: 'Billing address',
    invoiceRecipient: 'Invoice Recipient',
    expiringDate: 'Expiring Date',
    date: 'Due date',
    recipientRef:'Recipient Reference',
    reference:'Reference',
    invoiceRow: 'Invoice Row',
    productName: 'Name/Description',
    quantity:'Quantity',
    enterQuantity:'Enter quantity',
    unitPrice: 'Unit price (excl VAT)',
    vatAmount: 'VAT Amount',
    addInvoiceRow: 'Add invoice row',
    draft:'Save as draft',
    sendAttest: 'Send for attest',
    previewInvoice: 'Preview invoice',
    address: 'Address',
    postalCode:'Postal Code',
    city: 'City',
    totalPriceRow: 'Row amount',
    changeStatus: 'Change status',
    paid:'Paid',
    sentInvoices: 'Sent Invoices',
  },
  QRCode: {
    title: 'Title',
    name: 'Internal name for collections (optional)',
    orgQRLabel: "QR-code",
    thereIsComment: 'Require payers to enter a comment',
    generate: 'Generate QR-code',
    create: 'QR-code for collections',
    totalPaid:'Total paid',
    totalPayments:'Total payments',
    amount: 'Custom amount to contribute',
    choosePaymentMethod: 'Choose payment method',
    label: 'QR codes for fundraisers',
    totalCollected: 'Total collected',
    disable: 'Disable collection',
    enable: 'Enable collection',
    addCollection: 'Add collection',
    listActive: 'List active QR',
    listInactive: 'List inactive QR',
    defaultAmounts: 'Default Amounts',
    defaultAmountsToContribute: 'Default amount to contribute',
    addCustomAmount: 'Add custom amount',
    paymentsReceived: 'Payments received',
  },
  infoBox: {
    internalTransaction: 'Här kan du föra över pengar mellan olika organisationer inom Digitala Kassan. En genomförd överföring äger rum omedelbart och syns på båda kontonas kontoutdrag. \nEn intern överföring skapar ingen verklig bokföring, och funktionen kan inte heller användas för att flytta pengar ut ur systemet.',
    reportProceeding: 'Här kan du lämna in ersättningsbegäran för arvode, bilersättning och utlägg du haft. \nEn inlämnad begäran attesteras av personal inom LRF och betalas ut löpande till det konto du angett. \nEn ersättningsbegäran registreras för den organisation du tillhör och blir en utgift där. \nOm du tillhör flera organisationer behöver du säkerställa att du lämnar in begäran för rätt organisation. \nOBS att moms på utlägg kommer inte belasta organisationens konto.',
    salarySheet: 'Här hittar du dina lönespecifikationer med information om den ersättning du fått utbetald. \nLönespecifikationer genereras i början av en månad och innehåller information om utbetalningar som genomförts föregående månad.',
    quickPayment: 'Här kan du skicka ut e-postmeddelande till en eller flera mottagare där du ber om pengar. \nMeddelandet innehåller en länk till Digitala Kassans betalsida, och de pengar som betalas in blir omedelbart tillgängliga på organisationens konto här i kassan. \nDenna funktion kan användas t ex för att enkelt ta in deltagaravgifter innan ett arrangemang ägt rum. \nOBS att beloppet innehåller moms samt 2,5% avgift för tjänsten. \nExempel: begär du 100 kr kommer 20 kr avgå i moms samt 2.50 kr i avgift, så organisationens kassa kommer öka med 77.50 kr ',
    qrPayment:'Här kan du skapa en QR-kod för insamling av pengar, till exempel för entréavgifter, parkering eller annat som görs på plats vid ett arrangemang. \nQR-koden leder till en betalsida där man sedan kan välja betalsätt (Swish eller kort) och du kan i förväg bestämma vilka belopp som ska vara möjliga att ange. \nOBS att det inte är en swish-kod utan man ska använda mobilkameran för att skanna. ',
    createInvoice: 'Här kan du skapa fakturor som skickas ut via e-post till den mottagare du anger. \nFakturan ställs ut med Lantbrukarnas Ekonomi AB som avsändare och behöver attesteras av personal inom LRF innan den skickas ut.',
    attestPrimary: "Here you see the cases that belong to the primary cost centers that belong to your user. If something is not right, contact support",
    attest: "Here you see the cases that belong to your organization, but not any of your primary cost centers. If something is not right, contact support",
    duplicatePayment: 'This payment has been removed from the basket, as it is not allowed to sign for two payments with the same amount and account number, please make this payment on another basket.',
    duplicatePaymentStatus: 'This payment has been removed from the basket, there is still a payment pending to sign in the list with the same creditor name, accounting iban and amount, please wait until it is fully completed so you can add it to a basket.'
},
  sidebar: {
    reimbursement:'Report Proceedings',
    salarySheet: 'Salary Sheet',
    digitalAccount:'Digital Account',
    payInvoice:'Pay Invoice',
    collect:'Collect',
    quickPayments:'Quick Payments',
    QRcode: "QR-code",
    invoice:'Invoice',
    orgUsers: 'Organization Users',
    orgDetails: 'Organization Details',
    attest:'Attest',
    allAccount: 'All Account',
    allAttest: 'All Attest',
    internalTransaction:'Internal Transaction',
    bankTransfers: 'Bank Transfers',
    sentInvoices: 'Sent Invoices',
    honorarium:'Honorarium',
    reports:'Reports',
    taxAuthority: "Reports to the Tax Authority",
    exportSie:'Export SIE',
    org:'Organization',
    user: 'User',
    roles:'Roles',
    OPtransaction: 'Open Payment Transacciones',
  }
};
