import React, {useEffect, useState} from "react";
import SortableList from "../../Common/Components/Lists/SortableList";
import AdminResponseAttestModel from "../Model/Response/AdminResponseAttestModel";
import {Button, Col, Row} from "reactstrap";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {useSelector} from "react-redux";
import useApiCallService from "../../Common/Helpers/ApiCall";

export function AdminAttestListHistory() {
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();
    const { userData } = useSelector(state => state.auth);
    const { t } = useTranslation();

    const [apiResponseUserCostPlaceList, setApiResponseUserCostPlaceList] = useState(null);

    // GET USER COST PLACES
    useEffect(() => {
        const handleUserCostPlaceList  = (response) => {
            setApiResponseUserCostPlaceList(response);
        };
        const GetUserCostPlaceList = async () => {
            if (userData?.id) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/usersetting/primary-costplace?id=" + userData?.id,
                    };
                    await ApiCall(apiCallOptions, handleUserCostPlaceList);
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetUserCostPlaceList();
    }, [userData]);

    const renderActionColumn = (item) => {
        return (
            <>
                <button className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.ATTEST_INFO}?id=${item.id}&from=history`);
                        }} >
                    <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('button.info') }
                    </div>
                </button>
            </>
        );
    }

    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const columns = [
        'createdAt',
        "recipient",
        "orgName",
        "costPlace",
        "model",
        "modelVisualId",
        "modelStartDate",
        "amount",
        "status",
        "approvedDeniedByName",
        "updatedAt",
    ];

    if (isSuperAdmin === true) {
        columns.unshift('id');
    }
    const ignoreFields = ["Id", "OrgId", "ModelId", "CreatedBy"];
    const listOptions = {
        "model": AdminResponseAttestModel,
        'columns':columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/attest/admin/search-history",
        "nameFile": t('attest.attest'),
        "modelType" : "Attest",
        "ignoreFields" : [...columns, "createdByName"],
        "urlGetSearch" : "/attest/admin/search-history",
    };

    return <>
        <Col lg={12} className='card card-backend p-4 mb-5'>
            <Row>
                <Col lg={6}>
                    <h2 className='rokkitt-font'>{t('attest.labelListHistory')}</h2>
                </Col>
                <Col lg={6} className="text-end">
                    {apiResponseUserCostPlaceList && apiResponseUserCostPlaceList.primaryCostPlaces ?
                        <Button className="bg-green me-3 mb-2" onClick={() => {
                            navigate(`${ADMIN_PATHS.ATTEST_LIST_PENDING_PRIMARY}`);
                        }}>
                            {t('openPayment.myItems')}
                        </Button>
                        : ''}
                    <Button className="bg-green mb-2" onClick={() => {
                        navigate(`${ADMIN_PATHS.ATTEST_LIST_PENDING}`);
                    }}>
                        {t('openPayment.otherItems')}
                    </Button>
                </Col>
            </Row>
            <SortableList
                listOptions={listOptions}
            />
        </Col>
    </>
}