import ReactDOM from 'react-dom';

/*
You can use this component in the try-catch API responses
Example: try {
    FlashMessage('Login Successfully', 'flash-messageSuccess');
} catch (ex) {
    FlashMessage(ex.response.data.title + '. ' + 'Please try again', 'flash-messageError');
}

FlashMessage(<Component jsonString={errorMessage} />, 'flash-messageError', 10000);

If "close" variable is true, you must add a button with the id "closeButtonMessage" in "APImessage" variable
Example in <FlashMessageInsufficientAccountAmount>:
<div className="p-0 position-relative">
    <i id="closeButtonMessage" className="bx bx-x fs-4 position-absolute end-0 z-3" />
</div>
*/

export function FlashMessage(APImessage, type, time = null, close = false) {
    let flashMessage = APImessage;
    const messageDiv = document.getElementById('flash-message');
    const overlay = document.getElementById('overlay');

    if (messageDiv && overlay) {
        const CloseMessage = () => {
            // NEXT LINE IS IF YOU USE "ReactDOM.render(flashMessage, messageDiv);"
            ReactDOM.unmountComponentAtNode(messageDiv);
            messageDiv.style.display = 'none';
            overlay.style.display = 'none';
            document.getElementById('flash-message').classList.remove(type);
            flashMessage = '';
        }

        // NEXT LINE DO THE SAME AS "ReactDOM.render(flashMessage, messageDiv);", DONE THIS FOR CAN PASS A COMPONENT TOO
        // messageDiv.innerHTML = flashMessage;
        ReactDOM.render(flashMessage, messageDiv);
        document.getElementById('flash-message').classList.add(type);

        messageDiv.style.display = 'block';
        messageDiv.style.textAlign = 'center';
        messageDiv.style.margin = '0 auto';

        overlay.style.display = 'block';
        overlay.style.alignContent = 'center';

        if (close === true) {
            setTimeout(() => {
                const closeBtn = document.getElementById('closeButtonMessage');
                if (closeBtn) {
                    closeBtn.addEventListener('click', () => {
                        CloseMessage();
                    });
                }
                // IF YOU WANT TO CLOSE THE MESSAGE WHEN CLICK OUTSIDE
                overlay.addEventListener('click', () => {
                    CloseMessage();
                });
            }, 100);
        }

        if (close === false) {
            setTimeout(() => {
                CloseMessage();
            }, time ?? 2500);
        }
    }
}