import React from 'react';
import {useTranslation} from "react-i18next";

export const FlashMessageInsufficientAccountAmount = ({jsonString}) => {
    const { t } = useTranslation();

    const json = JSON.parse(jsonString);

    return (
        <>
            <div className="p-0 position-relative">
                <i id="closeButtonMessage" className="bx bx-x fs-4 position-absolute end-0 z-3" />
            </div>
            <table>
                <thead>
                <tr>
                    <td colSpan="2" className="text-center border-0">{t("attest.notEnoughFunds")}</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="border-0">{t("attest.balanceAccount")}:</td>
                    <td className="border-0 text-end">{json.AccountBalance} kr</td>
                </tr>
                <tr>
                    <td className="border-0">{t("attest.approvedButNotPaidOut")}:</td>
                    <td className="border-0 text-end">-{json.AttestApproved} kr</td></tr>
                    <tr><td className="border-0">{t("attest.thisProceeding")}:</td><td className="border-0 text-end">-{json.Amount} kr</td></tr>
                    <tr><td className="border-0"></td><td className="border-0"></td></tr>
                    <tr><td className="border-0">{t("attest.missingFunds")}:</td><td className="border-0 text-end">-{json.MissingAmount} kr</td></tr>
                </tbody>
            </table>
        </>
    );
};