import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";

class RequestReimbursementV2 {
    constructor({
                    id = '',
                    orgId = '',
                    date = '',
                    toDate = '',
                    purpose = '',
                    costBearerId = '',
                    status = '',
                    fixeds = [],
                    mileage = '',
                    expenses = [],
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.date = date;
        this.toDate = toDate;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.status = status;
        this.fixeds = fixeds;
        this.mileage = mileage;
        this.expenses = expenses;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const date = i18n.t('modelLabel.date');
        const purpose = i18n.t('reimbursement.purpose');
        const costBearerId = i18n.t('reimbursement.costBearer');
        const fixeds = i18n.t('reimbursement.fixed');
        const mileage = i18n.t('reimbursement.mileage');
        const expenses = i18n.t('reimbursement.expense');
        const required = i18n.t('validationMessage.fieldRequired');
        const orgId = i18n.t('modelLabel.org');

        let optionsCostBearer = [];
        if (optionsData && optionsData.costBearer) {
            optionsData.costBearer.map(item => {
                optionsCostBearer.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        let optionsOrg = [];
        if (optionsData && optionsData.org) {
            optionsData.org.map(item => {
                optionsOrg.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        const completeSchema = yup.object().shape({
            id: string().nullable(),
            orgId: string().nullable(),
            date: string().nullable().test("is-required", `${required}`, value => {
                return value !== "";
            }),
            toDate: string().nullable(),
            purpose: string().nullable().test("is-required", `${required}`, value => {
                return value !== "";
            }),
            costBearerId: string().nullable().test("is-not-empty", `${i18n.t('validationMessage.validOption')}`, value => {
                return value !== "";
            }),
            status: string().nullable(),
        });

        const completeSchemaSave = yup.object().shape({
            id: string().nullable(),
            orgId: string().nullable(),
            date: string().nullable(),
            toDate: string().nullable(),
            purpose: string().nullable(),
            costBearerId: string(),
            status: string().nullable(),
        });

        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            orgId :{
                'id': "orgId",
                'label': orgId,
                'form': {
                    'type': 'select',
                    'options': optionsOrg,
                    'default': '',
                    'placeholder': orgId,
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            },
            date :{
                "id": "date",
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.date,
                    "maxDate": new Date(),
                },
                'value': (item) => item.date ? ChangeDateFormat('date', item.date) : "",
            },
            toDate :{
                "id": "toDate",
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.toDate,
                    "maxDate": new Date(),
                },
                'value': (item) => item.toDate ? ChangeDateFormat('date', item.toDate) : "",
            },
            purpose :{
                'id': "purpose",
                'label': purpose,
                'form': {
                    'type': 'text',
                    'placeholder': purpose,
                    'validation': completeSchema.fields.purpose,
                },
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'id': "costBearerId",
                'label': costBearerId,
                'form': {
                    'type': 'select',
                    'options': optionsCostBearer,
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            status :{
                'value': (item) => item.status || "",
            },
            fixeds :{
                'value': (item) => item.fixeds || "",
            },
            mileage :{
                'value': (item) => item.mileage || "",
            },
            expenses :{
                'value': (item) => item.expenses || "",
            },
        }

        if (validationSchema && validationSchema === "send") {
            return completeSchema;
        }
        if (validationSchema && validationSchema === "save") {
            return completeSchemaSave;
        }

        return attributes;
    }
}
export default RequestReimbursementV2;