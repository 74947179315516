import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponseReimbursement from "../Models/Response/ResponseReimbursement";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {Button, Col, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {ADMIN_PATHS, REPORT_PROCEEDING_PATHS} from "../../config";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";
import ResponseUserSettingsModel from "../../UserSettings/Model/Response/ResponseUserSettingsModel";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";

const ReportProceedingList = () => {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();

    const navigate = useNavigate();

    const [deleteApiResponse, setDeleteApiResponse] = useState(null);
    //const [individualReimbursement, setIndividualReimbursement] = useState(null);

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [reimbursementId, setReimbursementId] = useState(null);

    let permission = false;
    if (CheckUserPermission("reimbursement.create") || CheckUserPermission("reimbursement")) {
        permission = true;
    }

    let permissionDelete = false;
    if (CheckUserPermission("reimbursement.delete") || CheckUserPermission("reimbursement")) {
        permissionDelete = true;
    }

    // HANDLE MODAL DELETE
    const handleModalDelete = async () => {
        const apiCallOptions = {
            method: "delete",
            url: `/reimbursement/`,
            data: {
                id: reimbursementId
            }
        };
        try {
            await ApiCall(apiCallOptions, setDeleteApiResponse);
            setDisplayDeleteModal(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (deleteApiResponse !== null) {
            CustomReload(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
        }
    }, [deleteApiResponse]);

    const modalContent = (
        <div>
            {`${t('validationMessage.modalVar')}?`}
        </div>
    );
    const modalFooter = (
        <>
            <Button className="box-green border-0" onClick={handleModalDelete}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    const renderActionColumn = (item) => {
        return (
            <>
                {item.status === "Förslag" ? (
                    <>
                        {
                            permissionDelete === true ?
                                    <Button className="box-red border-0 position-relative me-2 rounded" onClick={() => {
                                        setDisplayDeleteModal(true);
                                        setReimbursementId(item.id);
                                    }}>
                                        <i className="bx bx-x-circle fs-5 align-middle pb-1"/>
                                        <div className="tooltip-custom-button">
                                            {t("button.delete")}
                                        </div>
                                    </Button>
                                : ''
                        }

                        <Button className="bg-green-light text-green border-0 position-relative" onClick={() => {
                            navigate(`${REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING}?id=${item.id}`)
                        }}>
                            <i className="bx bx-edit fs-5 align-middle pb-1"/>
                            <div className="tooltip-custom-button">
                                {t("button.edit")}
                            </div>
                        </Button>
                        {/*<Button className="bg-green-light text-green border-0 position-relative ms-2" onClick={() => {
                            navigate(`${REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_V2}?id=${item.id}`)
                        }}>
                            <i className="bx bx-edit fs-5 align-middle pb-1"/> v2
                            <div className="tooltip-custom-button">
                                {t("button.edit")}
                            </div>
                        </Button>*/}
                    </>
                ) : (
                    <Button className="bg-green-light text-green border-0 position-relative" onClick={() => {navigate(`${REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_INFO}?id=${item.id}`)}}>
                        <i className="bx bx-show fs-5 align-middle pb-1" />
                        <div className="tooltip-custom-button">
                            {t("button.info")}
                        </div>
                    </Button>
                )}
            </>
        );
    }

    const renderButtonColumn = (item) => {
        return (
            <>
                {permission ?
                    <Col lg={12} className="mt-4 px-3">
                        <CustomButton customClasses="rounded" text={t('reimbursement.newProcessing')} icon="bx bx-plus pe-2" onClick={() => {
                                navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING)}
                            }
                        />
                        {/*<CustomButton customClasses="rounded mt-1" text={`${t('reimbursement.newProcessing')} v2`} icon="bx bx-plus pe-2" onClick={() => {
                            navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_V2)}
                        }
                        />*/}
                    </Col>
                : ""}
            </>
        );
    }

    const columns = [ "visualId", 'date', 'purpose', 'orgName', 'grossAmount', 'createdAt', 'status',];
    const listOptions = {
        "model": ResponseReimbursement,
        "columns": [
            "visualId",
            'date',
            {
                "field" : "purpose",
                "label" : t('reimbursement.purpose'),
                "tdClass": (item) => "auto-wrap-td",
                "value": (item) => item.purpose
            },
            'orgName',
            {
                "field" : "grossAmount",
                "label" : t('modelLabel.grossAmount'),
                'insideClass' : (item)=> item.grossAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                "value": (item) => item.grossAmount ? ToCustomDecimal(item.grossAmount) +' kr' : ""
            },
            'createdAt',
            'status',
        ],
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/reimbursement/search" ,
        "nameFile": t('reimbursement.reportProcessings'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/reimbursement/search",
    };

    return (
        <>
            <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                         body={modalContent} footer={modalFooter}/>
            <Col lg="12" className='card card-backend p-4 mb-5'>
                <h2 className="pb-2 ps-1 rokkitt-font">{t('reimbursement.reportProcessings')}</h2>
                <SortableList
                    listOptions={listOptions}
                />
            </Col>

        </>
    );
};

export default ReportProceedingList;