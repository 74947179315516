import {Accordion, AccordionItem, Button, Col, Collapse, Row} from "reactstrap";
import classnames from "classnames";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementMileage from "../Models/Request/RequestReimbursementMileage";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import RequestPassenger from "../Models/Request/RequestPassenger";
import React, {useEffect, useRef, useState} from "react";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function ReimbursementMileage({ data, onChange, onValidate, onCancel, editMode = false }) {
    const { t } = useTranslation()
    // CANCEL STATE
    const [cancelModel, setCancelModel] = useState(data);

    // STATES
    const [accordionStatus, setAccordionStatus] = useState('1');
    const [localDisplay, setLocalDisplay] = useState(false);
    //REF
    const formDivRefMileage = useRef(null);

    // CANCEL MODEL
    useEffect(() => {
        if (editMode === true) {
            const copyModel = _.cloneDeep(data);
            setCancelModel(copyModel);
            setLocalDisplay(true);
        }
    }, []);

    return (
        <>
            <Accordion id="accordionBordered" open={accordionStatus}>
                <AccordionItem className={`accordion-grey bg-green mb-4 rounded ${localDisplay === false ? 'border-0' : 'border-2 border-green'}`}>
                    <h2 className="accordion-header" id="accordionborderedExample2">
                        <button className={classnames(`py-2 pe-1 w-100 accordion-button d-flex justify-content-between ${localDisplay === false ? 'accordion-grey text-green' : 'bg-green text-white'} fw-bold border-bottom-0`, {collapsed: !localDisplay})} type="button" style={{cursor: "pointer"}}
                        onKeyDown={() => {setLocalDisplay(true)}} onClick={() => {setLocalDisplay(!localDisplay)}}>
                            <div>
                                {t('reimbursement.addMileageCompensation')}
                            </div>
                            {localDisplay === false ? <i className="bx bx-chevron-down fs-3"></i> : <i className="bx bx-chevron-up text-white fs-3"></i>}
                        </button>
                    </h2>
                    <Collapse isOpen={localDisplay} className="accordion-collapse" id="accor_borderedExamplecollapse2">
                        <div className="accordion-body" ref={formDivRefMileage}>
                            <Row>
                                <Col lg={5}>
                                    <CustomInput model={RequestReimbursementMileage} attribute='from' data={data?.from} customChange={(e) => onChange(e, "from")}/>
                                </Col>
                                <Col lg={1} className="align-self-end text-center pb-4">
                                    -
                                </Col>
                                <Col lg={6} className="align-self-end">
                                    <CustomInput model={RequestReimbursementMileage} attribute='to' data={data?.to} customChange={(e) => onChange(e, "to")}/>
                                </Col>
                                <Col lg={7} className="pt-3">
                                    <CustomInput model={RequestReimbursementMileage} attribute='km'
                                                 data={data?.km !== 0 ? data?.km : ""}
                                                 customChange={(e) => {
                                                     onChange(e, "km");
                                                 }}/>
                                </Col>
                                <Col lg={1} className="align-self-end text-center pb-4">
                                    =
                                </Col>
                                <Col lg={4} className="align-self-end pb-3">
                                    <div id="total-amount-km"
                                         className='box-beig'>{data?.amount !== 0 ? ToCustomDecimal(data?.amount || 0) : 0} kr
                                    </div>
                                </Col>
                                <Col lg={12} className="text-end pt-3">
                                    {(data?.passengers === null || data?.passengers?.length === 0) && editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModel)}>  {t('button.cancel')}</Button> : ""}
                                    <CustomSubmitButton text={editMode === true ? t('button.save') : t('reimbursement.addCompensation')} customClass={`${data?.passengers !== null && data?.passengers?.length > 0 ? 'd-none' : ''}`} customClick={onValidate} formDivRef={formDivRefMileage}/>
                                </Col>
                                <div className="dashed-border pt-4 mb-3 "/>
                                {data?.passengers === null || data?.passengers?.length === 0 ?
                                    <Col lg={12} className="d-flex justify-content-center">
                                        <CustomButton text={t('reimbursement.addPassengers')}
                                                      icon="bx bxs-plus-circle pe-3 text-green" iconSize={4}
                                                      hoverIconSize={4}
                                                      customClasses='text-green bg-transparent border-0 rounded-2 fw-bold'
                                                      hoverButtonShadow={true} onClick={() => {onChange("", "addPassenger")}}/>
                                    </Col>
                                    : ''}
                                {data?.passengers !== null && data?.passengers?.length > 0 ?
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={5}>
                                                <h5>   {t('reimbursement.passengers')}</h5>
                                            </Col>
                                            <Col lg={7} className='d-flex justify-content-end p-0'>
                                                <CustomButton text={t('reimbursement.cancelPassenger')} customClasses='text-green bg-transparent border-0 rounded-2 fw-bold'
                                                              hoverButtonShadow={true} onClick={() => {onChange("", "cancelPassenger")}}/>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="text-green d-flex align-items-center mb-3">
                                                    <i className='bx bx-info-circle fs-5 pe-2'/>{t('reimbursement.passengersHelpText')}
                                                </div>
                                            </Col>
                                            <Col lg={12} xxl={4}>
                                                <CustomInput model={RequestPassenger} attribute='quantity' data={data?.passengers[0]?.quantity !== 0 ? data?.passengers[0]?.quantity : ""}
                                                             customChange={(e) => {
                                                                 onChange(e, "quantityPassenger");
                                                             }}/>
                                            </Col>
                                            <Col lg={12} xxl={4} className='align-self-end'>
                                                <CustomInput model={RequestPassenger} attribute='km'
                                                             data={data?.passengers[0]?.km !== 0 ? data?.passengers[0]?.km : ""}
                                                             customChange={(e) => {
                                                                 onChange(e, "kmPassenger");
                                                             }}
                                                />
                                            </Col>
                                            <Col lg={1} xxl={1} className="align-self-end text-center pb-4">
                                                =
                                            </Col>
                                            <Col lg={11} xxl={3} className="align-self-end pb-3">
                                                <div id="total-amount-passenger"
                                                     className='box-beig'>{data?.passengers[0]?.amount !== 0 ? ToCustomDecimal(data?.passengers[0]?.amount) : 0} kr
                                                </div>
                                            </Col>                                     
                                            <Col lg={12} className="text-end pt-3">
                                                {editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModel)}> {t('button.cancel')}</Button> : ""}
                                                <CustomSubmitButton text={editMode === true ?  t('button.save') : t('reimbursement.addMileage')} customClick={onValidate} formDivRef={formDivRefMileage} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    : ''}
                            </Row>
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>
        </>
    );
}
